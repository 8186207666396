import React, { FC, useState } from 'react';
import classNames from 'classnames';
import s from './Overlay.scss';
import { useStyles } from '@wix/tpa-settings/react';
import stylesParams from '../../../../../../stylesParams';
import { PlayButton } from '../../../../common/components/PlayButton/PlayButton';
import { Button } from '../../../../common/components/Button/Button';
import { Playable } from '../../../../common/components/Player/players/Playable/Playable';
import { Picture } from '../../../../common/components/Picture/Picture';

type ButtonProps = {
  text: string;
  handler(): void;
};

type Props = {
  playButton?: ButtonProps;
  paidButton?: ButtonProps;

  content: React.ReactNode;
  buttons?: React.ReactNode;

  ariaLabel?: string;
  coverUrl?: string;
  videoUrl?: string;
};

const useOverlaySettings = () => {
  const styles = useStyles();

  const opacity = styles.get(stylesParams.mainItemOverlayOpacity) / 100;
  return { opacity };
};

const OverlayContent: FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { opacity } = useOverlaySettings();

  return (
    <div
      className={s.content}
      style={{
        backgroundColor: `rgba(0,0,0,${opacity})`,
      }}
    >
      {children}
    </div>
  );
};

export const OverlayHeaderTitle: FC<{
  children: string;
}> = ({ children }) => {
  return (
    <h3 className={s.title} data-hook="classic-overlay-title">
      {children}
    </h3>
  );
};

export const OverlayHeader: FC<{
  children: React.ReactNode;
  playButton?: ButtonProps;
}> = ({ children, playButton }) => (
  <header className={s.overlayHeader} onClick={playButton?.handler}>
    {children}
  </header>
);

export const Overlay: FC<Props> = ({
  ariaLabel,
  playButton,
  paidButton,
  videoUrl,
  coverUrl,
  content,
  buttons,
}) => {
  const [playing, setPlaying] = useState(false);

  return (
    <section className={s.root} aria-label={ariaLabel}>
      {videoUrl && (
        <Playable
          src={videoUrl}
          playing
          muted
          loop
          controlsHidden
          onPlay={() => setPlaying(true)}
        />
      )}
      {coverUrl && (
        <Picture
          className={classNames(s.picture, {
            [s.hidden]: coverUrl && playing,
          })}
          src={coverUrl}
          breakpoints={[
            {
              min: 0,
              width: 768,
              height: 432,
            },
          ]}
        />
      )}
      <OverlayContent>
        {playButton ? (
          <button
            onClick={playButton.handler}
            tabIndex={-1}
            className={s.overlayPlayButton}
          />
        ) : null}
        <div className={s.contentCenter}>
          {content}
          <div className={s.buttons}>
            {playButton ? <PlayButton>{playButton.text}</PlayButton> : null}
            {paidButton && (
              <Button
                data-hook="classic-overlay-paid-button"
                onClick={paidButton.handler}
              >
                {paidButton.text}
              </Button>
            )}
            {buttons}
          </div>
        </div>
      </OverlayContent>
    </section>
  );
};
